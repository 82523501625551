@import 'src/styles/variables';

.mdc-linear-progress {
    height: 12px;
    background: #cdd7da;
}

.mdc-linear-progress__bar-inner {
    border-width: 12px;
    border-color: $beat-primary-green;
}

.mdc-linear-progress__buffering-dots {
    display: none;
}
