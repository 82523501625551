@import 'src/styles/variables';

$greenStart: #08823a;
$greenEnd: #0fe163;

$blueStart: #006a96;
$blueEnd: #25c2ff;

$redStart: #b71c44;
$redEnd: $beat-xmas-red;

$purpleStart: #924696;
$purpleEnd: #d209ee;

@mixin greenBackgroundStart() {
    background: $greenStart;
}

@mixin greenBackgroundEnd() {
    background: $greenEnd;
}

@mixin greenBackgroundTransition() {
    background: linear-gradient(90deg, $greenStart, $greenEnd);
}

@mixin blueBackgroundStart() {
    background: $blueStart;
}

@mixin blueBackgroundEnd() {
    background: $blueEnd;
}

@mixin blueBackgroundTransition() {
    background: linear-gradient(90deg, $blueStart, $blueEnd);
}

@mixin redBackgroundStart() {
    background: $redStart;
}

@mixin redBackgroundEnd() {
    background: $redEnd;
}

@mixin redBackgroundTransition() {
    background: linear-gradient(90deg, $redStart, $redEnd);
}

@mixin purpleBackgroundStart() {
    background: $purpleStart;
}

@mixin purpleBackgroundEnd() {
    background: $purpleEnd;
}

@mixin purpleBackgroundTransition() {
    background: linear-gradient(90deg, $purpleStart, $purpleEnd);
}
