@import 'src/styles/variables';

.banner__message {
    color: white;
    width: 170px;
    font-weight: bold;
    font-size: 1rem;
}

.banner__points-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    height: 100%;
    color: white;
}

.banner__points-container img {
    margin-bottom: 5px;
}

.banner__points-container span {
    font-size: $fontSize-small;
}

.banner__content {
    justify-content: var(--message-alignment);
    align-items: center;
}
