@import 'src/styles/variables';

.bounties {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 3rem;
}

.mdc-list {
    width: 100%;
    margin-top: 2rem;
}

.mdc-list-item {
    height: 5rem;
    padding-right: 0;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.bounties-list-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-left: 12px;
}

.bounties-list-item__banner {
    justify-content: flex-start;
    align-items: center;
}

.bounties-list-item__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 90%;
    margin: 0 auto;
    color: white;
}

.bounties-list-item__name {
    width: 30%;
    font-size: 0.9rem;
    font-weight: bold;
    line-height: 18px;
}

.bounties-list-item__description {
    display: flex;
    align-items: center;
    width: 60%;
    height: 100%;
    overflow: hidden;
}

.bounties-list-item__description-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55%;
    padding: 5px;
    font-size: 0.7rem;
    border-radius: 10px;
    border: 1px solid white;
    line-height: 16px;
}
