@import 'src/styles/variables';

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    .loading__spinner-wrapper {
        position: relative;
    }

    .rmwc-circular-progress {
        display: flex;
        color: $beat-primary-green;
    }

    .loading__text {
        position: absolute;
        top: 60px;
        left: 38px;
    }
}
