.arrows {
    display: flex;
    flex-direction: column;

    img:first-child {
        z-index: 200;
        background: transparent;
    }

    img:nth-child(2) {
        margin-top: -4px;
        z-index: 199;
    }
}
