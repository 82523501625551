@import "src/styles/mixins";
@import "src/styles/variables";

.main {
    margin-bottom: 3rem;
}

.single-bounty {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}

.single-bounty__content {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 2px solid $beat-primary-blue;
}

.single-bounty__content--xmas {
    border-color: $beat-xmas-red !important;
}

.single-bounty__content--diy {
    border-color: $beat-diy-purple;
}

.single-bounty__days {
    display: flex;
    flex-direction: column;
    margin: 3rem 0 1rem;
}

.single-bounty__input-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.single-bounty__number-input {
    height: 37.5px;
    input {
        text-align: center;
        height: 100%;
        font-size: 16px;
        width: 100px;
        border: none;
        outline: 1px solid $beat-gray-1;
    }

    input[type="number"]::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
}

.single-bounty__decrease-days,
.single-bounty__increase-days {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 41px;
    width: 41px;
    margin-top: -0.5px;
    background-color: $beat-primary-blue;
    box-sizing: border-box;
    @include disableSelect;

    &:hover {
        cursor: pointer;
    }

    i {
        color: white;
        font-size: 0.9rem;
    }
}

.single-bounty__decrease-days--disabled,
.single-bounty__increase-days--disabled {
    background-color: $beat-gray-1;
}

.single-bounty__actions {
    position: relative;

    .add-person {
        position: absolute;
        right: -50px;
        top: 20px;
    }
}

.single-bounty__start-button {
    width: 140px;
    background-color: $beat-primary-green !important;
    margin: 1rem 0;
    font-size: 18px;
    color: white !important;
    border-color: $beat-gray-1 !important;

    .mdc-button__icon {
        margin: 0;
    }

    .rmwc-circular-progress__circle {
        color: $beat-primary-white;
    }
}

.single-bounty__done {
    margin: -6rem 0 -8rem;
    z-index: -100;
    font-weight: bold;
    font-size: 1.4rem;
}

.single-bounty .single-bounty__content,
.single-bounty .single-bounty__content {
    margin-bottom: 0;
}

.single-bounty__done--xmas,
.single-bounty__done--diy {
    margin: -0.8rem 0 -2rem;
}
