@import 'src/styles/variables';

:root {
    //--mdc-theme-primary: #1cd466;
    --mdc-theme-primary: red;
}

.limit-width {
    max-width: 500px;
    width: 95%;
    margin: 0 auto;
}

main.main {
    margin-top: 0.5rem;
}

.debug-box {
    display: flex;
    justify-content: center;
}
