@import 'background-colors';

.colored-banner {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.colored-banner__background {
    height: 100%;
}

.colored-banner__background--start {
    flex: 1;
}

.colored-banner__background--transition {
    flex: 20;
}

.colored-banner__background--end {
    flex: 1;
}

.colored-banner__green-start {
    @include greenBackgroundStart;
}

.colored-banner__green-transition {
    @include greenBackgroundTransition;
}

.colored-banner__green-end {
    @include greenBackgroundEnd;
}

.colored-banner__blue-start {
    @include blueBackgroundStart;
}

.colored-banner__blue-transition {
    @include blueBackgroundTransition;
}

.colored-banner__blue-end {
    @include blueBackgroundEnd;
}

.colored-banner__red-start {
    @include redBackgroundStart;
}

.colored-banner__red-transition {
    @include redBackgroundTransition;
}

.colored-banner__red-end {
    @include redBackgroundEnd;
}

.colored-banner__purple-start {
    @include purpleBackgroundStart;
}

.colored-banner__purple-transition {
    @include purpleBackgroundTransition;
}

.colored-banner__purple-end {
    @include purpleBackgroundEnd;
}

.colored-banner__overlay {
    display: flex;
    position: absolute;
}
