.main h2 {
    text-align: center;
}

.challenges {
    margin-top: 2rem;
}

.challenges__row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 20px;
}
