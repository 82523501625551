@import 'src/styles/variables';

.challenge-preview {
    margin: 5px;

    &:hover {
        cursor: pointer;
    }
}

.challenge-preview__count {
    text-align: left;
}

.challenge-preview__content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.challenge-preview__half-circle {
    display: flex;
    align-items: flex-end;
    border-style: solid;
    border-color: $beat-primary-blue;
    border-bottom: 0;
    box-sizing: border-box;
}

.challenge-preview__half-circle h4 {
    width: 100%;
    margin-bottom: 11px;
    text-align: center;
}

.challenge-preview__description {
    margin: 4px 0 6px;
    width: 100%;
    text-align: center;
    //overflow: hidden;
    //white-space: nowrap;
    //text-overflow: ellipsis;
}
