$beat-primary-green: #1cd466;
$beat-primary-blue: #00b0f0;
$beat-primary-gray: #6d6d6d;
$beat-primary-white: #f2f2f2;
$beat-primary-black: #121212;

$beat-gray-1: #b9b9b9;

$beat-xmas-red: #f70953;
$beat-diy-purple: #d209ee;

$fontSize-big: 3rem;
$fontSize-small: 0.8rem;
