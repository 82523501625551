@import 'src/styles/variables';

@media screen and (max-width: 230px) {
    .challenge-preview {
        width: 80px;
    }
    .challenge-preview__half-circle {
        width: 80px;
        height: 45px;
        border-width: 15px;
        border-top-left-radius: 60px;
        border-top-right-radius: 60px;
    }
    .challenge-preview__count {
        font-size: 0.5rem;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.45rem;
    }
    .challenge-preview__description {
        font-size: 0.5rem;
    }
}

@media screen and (min-width: 230px) {
    .challenge-preview {
        width: 100px;
    }
    .challenge-preview__half-circle {
        width: 100px;
        height: 55px;
        border-width: 20px;
        border-top-left-radius: 75px;
        border-top-right-radius: 75px;
    }
    .challenge-preview__count {
        font-size: 0.55rem;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.5rem;
    }
    .challenge-preview__description {
        font-size: 0.6rem;
    }
}

@media screen and (min-width: 280px) {
    .challenge-preview {
        width: 120px;
    }
    .challenge-preview__half-circle {
        width: 120px;
        height: 60px;
        border-width: 25px;
        border-top-left-radius: 85px;
        border-top-right-radius: 85px;
    }
    .challenge-preview__count {
        font-size: 0.7rem;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.5rem;
    }
}

@media screen and (min-width: 310px) {
    .challenge-preview {
        width: 140px;
    }
    .challenge-preview__half-circle {
        width: 140px;
        height: 70px;
        border-width: 30px;
        border-top-left-radius: 100px;
        border-top-right-radius: 100px;
    }
    .challenge-preview__count {
        font-size: $fontSize-small;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.65rem;
    }
    .challenge-preview__description {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 360px) {
    .challenge-preview {
        width: 160px;
    }
    .challenge-preview__half-circle {
        width: 160px;
        height: 80px;
        border-width: 30px;
        border-top-left-radius: 110px;
        border-top-right-radius: 110px;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.8rem;
    }
}

@media screen and (min-width: 400px) {
    .challenge-preview {
        width: 180px;
    }
    .challenge-preview__half-circle {
        width: 180px;
        height: 90px;
        border-width: 35px;
        border-top-left-radius: 125px;
        border-top-right-radius: 125px;
    }
    .challenge-preview__half-circle h4 {
        font-size: 0.9rem;
    }
}
