@import 'src/styles/variables';

.rmwc-icon {
    margin: 0;
    font-size: 2.2rem;
}

.rmwc-icon.checked {
    color: $beat-primary-green;
}

.rmwc-icon.indeterminate {
    color: #fcaf13;
}
